
import { trackCommunicationPreferences } from '@/tracking';
import { CurrentUser } from '@/types';
import Heading from '@/ui/Heading.vue';
import HeadingLevel from '@/ui/HeadingLevel.vue';
import Vue from '@/vueTyped';
import debounce from 'lodash/debounce';

export default Vue.extend({
    components: {
        Heading,
        HeadingLevel,
    },
    data() {
        return {
            debouncedSaveUserData: () => {},
            userData: {
                mobilePhone: '',
                communicationPreferences: {}
            } as CurrentUser,
        };
    },
    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            handler(val: CurrentUser) {
                if (val) {
                    this.userData.communicationPreferences = val.communicationPreferences;
                    this.userData.communicationPreferences = val.communicationPreferences;
                }
            },
        },
    },
    created() {
        this.debouncedSaveUserData = debounce(this.saveUserData, 750);
    },
    methods: {
        saveUserData() {
            this.$store.dispatch('updateUserDetails', this.userData);
            trackCommunicationPreferences(this.userData.communicationPreferences);
        },
    },
});
