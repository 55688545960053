
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import UserAvatar from '@/components/UserAvatar.vue';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import ActionFooter from '@/components/ActionFooter.vue';

export default Vue.extend({
    name: 'SetupProfile',
    components: {
        UserAvatar,
        ValidationProvider,
        ActionFooter,
    },
    props: {
        nextRoute: { type: String, default: '' },
    },
    data() {
        return {
            userData: {
                description: '',
            } as CurrentUser,
            avatarLoading: false,
            loading: false
        };
    },
    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            handler(val: CurrentUser) {
                if (val) {
                    this.userData.description = val.description;
                }
            },
        },
    },
    methods: {
        async updateProfilePhoto(file: File) {
            if (this.currentUser) {
                this.avatarLoading = true;
                await this.$store.dispatch('updateAvatar', { file });
                this.avatarLoading = false;
            }
        },
        async saveUserData() {
            this.loading = true;
            await this.$store.dispatch('updateUserDetails', {
                description: this.userData.description,
                successMessage: false,
            });
            this.loading = false;

            if (this.nextRoute.includes('://')) {
                location.assign(this.nextRoute);
            } else {
                this.$router.push(this.nextRoute);
            }
        },
    },

});
