
import BasePageContent from '@/components/BasePageContent.vue';
import Heading from '@/ui/Heading.vue';
import HeadingLevel from '@/ui/HeadingLevel.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    metaInfo: {
        title: 'Account',
    },

    components: {
        BasePageContent,
        Heading,
        HeadingLevel,
    },
});
